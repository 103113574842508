import React, { useState } from 'react';
import useTranslations from "../_customHooks/useTranslations";
import { makeStyles } from '@material-ui/core/styles';
import Banner from '../images/banner.png';
import PictoTeamSvg from '../components/svgs/pictoTeam';
import PictoMountainSvg from '../components/svgs/pictoMountain';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import WebIcon from '@material-ui/icons/Web';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import VisibilitySensor from "react-visibility-sensor";
import Grow from '@material-ui/core/Grow';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
	banner: {
		zIndex: -1,
		overflow: "hidden",
		height: "100vh",
		width: "100%",
		backgroundImage: 'url('+ Banner +')',
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		backgroundPosition: "center center",
		// backgroundAttachment: "fixed",
	},
	bannerText: {
		display: "flex",
		alignItems: "center",
		color: "white",
		fontSize: "4.5vw",
		marginLeft: "2vw"
	},
	who: {
		padding: theme.spacing(8, 2)
	},
	isRounded: {
		textTransform: "lowercase",
		borderRadius: "9999px",
    	paddingLeft: "calc(1em + .25em)",
    	paddingRight: "calc(1em + .25em)",
		[theme.breakpoints.down('sm')]: {
            fontSize: "15px",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.5rem",
        }
	},
	service: {
		padding: theme.spacing(8, 2),
		backgroundColor: "#eef3f2",
		minHeight: '500px'
	},
	serviceCard: {
		border: "solid 2px",
		borderColor: "rgba(0, 0, 0, 0.54)"
	},
	serviceItem: {
		fontSize: '4rem',
		color: theme.palette.secondary.main
	},
	contact: {
		padding: theme.spacing(8, 2),
		minHeight: '500px'	
	}
}));

const IndexPage = () => {
	const { banner, who, service, contact } = useTranslations()
	const classes = useStyles();

	let [activeService, setActiveService] = useState(false);
	let [activeContact, setActiveContact] = useState(false);

	const handleServiceParallax = (value) => {
		if (!activeService && value) {
			setActiveService(true);
		}
	};

	const handleContactParallax = (value) => {
		if (!activeContact && value) {
			setActiveContact(true);
		}
	};

	return ( 
		<React.Fragment>
			<section className={classes.banner}>
				<Box display="flex" justifyContent="center" width="50vw" height="100vh">
					<code className={classes.bannerText}>{banner.slogan}</code>
				</Box>
			</section>
			<section className={classes.who} id="who">
				<Container>
					<Grid container justifiy="center" spacing={4}>
						<Grid item xs={12} sm={12} md={6} style={{ alignSelf:"center"}}>
							<Typography variant="h2">
								{who.title}
							</Typography>
							<Box mt={2} />
							<Typography variant="h6" color="textSecondary" component="p">
								{who.description}
							</Typography>
							<Box mt={3} />
							<Button 
								className={classes.isRounded} 
								size="large" 
								variant="contained" 
								color="secondary"
								onClick={() => scrollTo("#contact")}>
								{who.button}
							</Button>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<PictoTeamSvg />
						</Grid>
					</Grid>
				</Container>
			</section>
			<section className={classes.service} id="service">
				<Container>
					<Box display="flex" flexDirection="column" alignItems="center" mb={4}>
						<Box mb={2}>
							<Typography variant="h2">
								{service.title}
							</Typography>
						</Box>
						<Typography variant="h6" color="textSecondary">
							<FormatQuoteIcon/><Box fontStyle="italic" component="span">{service.subtitle}</Box><FormatQuoteIcon/>
						</Typography>
					</Box>
					<Grid
						container
						spacing={3}
						direction="row"
						justify="center"
						alignItems="center">
						<Grid item xs={12} sm={12} md={4}>
							<VisibilitySensor 
								onChange={(isVisible) => handleServiceParallax(isVisible)}>
								<Grow in={activeService}>
									<Card className={classes.serviceCard} elevation={0}>
										<Box display="flex" justifyContent="flex-start" p={1}>
											<MobileFriendlyIcon className={classes.serviceItem} />
										</Box>
										<CardContent>
											<Typography gutterBottom variant="h5" color="textSecondary" component="p">
												{service.mobile.title}
											</Typography>
										</CardContent>
									</Card>
								</Grow>	
							</VisibilitySensor>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Grow
								in={activeService}
								style={{ transformOrigin: '0 0 0' }}
								{...(activeService ? { timeout: 1000 } : {})}>
								<Card className={classes.serviceCard} elevation={0}>
									<Box display="flex" justifyContent="flex-start" p={1}>
										<WebIcon className={classes.serviceItem} />
									</Box>
									<CardContent>
										<Typography gutterBottom variant="h5" color="textSecondary" component="p">
											{service.platform.title}
										</Typography>
									</CardContent>
								</Card>
							</Grow>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Grow
								in={activeService}
								style={{ transformOrigin: '0 0 0' }}
								{...(activeService ? { timeout: 2000 } : {})}>
								<Card className={classes.serviceCard} elevation={0}>
									<Box display="flex" justifyContent="flex-start" p={1}>
										<DesktopWindowsIcon className={classes.serviceItem} />
									</Box>
									<CardContent>
										<Typography gutterBottom variant="h5" color="textSecondary" component="p">
											{service.desktop.title}
										</Typography>
									</CardContent>
								</Card>
							</Grow>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Grow
								in={activeService}
								style={{ transformOrigin: '0 0 0' }}
								{...(activeService ? { timeout: 3000 } : {})}>	
								<Card className={classes.serviceCard} elevation={0}>
									<Box display="flex" justifyContent="flex-start" p={1}>
										<DescriptionIcon className={classes.serviceItem} />
									</Box>
									<CardContent>
										<Typography gutterBottom variant="h5" color="textSecondary" component="p">
											{service.landing.title}
										</Typography>
									</CardContent>
								</Card>
							</Grow>	
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Grow
								in={activeService}
								style={{ transformOrigin: '0 0 0' }}
								{...(activeService ? { timeout: 4000 } : {})}>
								<Card className={classes.serviceCard} elevation={0}>
									<Box display="flex" justifyContent="flex-start" p={1}>
										<CloudDoneIcon className={classes.serviceItem} />
									</Box>
									<CardContent>
										<Typography gutterBottom variant="h5" color="textSecondary" component="p">
											{service.cloud.title}
										</Typography>
									</CardContent>
								</Card>
							</Grow>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Grow
								in={activeService}
								style={{ transformOrigin: '0 0 0' }}
								{...(activeService ? { timeout: 5000 } : {})}>
								<Card className={classes.serviceCard} elevation={0}>
									<Box display="flex" justifyContent="flex-start" p={1}>
										<SettingsInputComponentIcon className={classes.serviceItem} />
									</Box>
									<CardContent>
										<Typography gutterBottom variant="h5" color="textSecondary" component="p">
											{service.api.title}
										</Typography>
									</CardContent>
								</Card>
							</Grow>
						</Grid>
					</Grid>
				</Container>
			</section>
			<section className={classes.contact} id="contact">
				<Container>
					<Grid container spacing={4}>
						<Grid item xs={12} sm={12} md={6}>
							<PictoMountainSvg />
						</Grid>
						<Grid item xs={12} sm={12} md={6} style={{ alignSelf:"center"}}>
							<VisibilitySensor 
								onChange={(isVisible) => handleContactParallax(isVisible)}>
								<React.Fragment>
									<Grow in={activeContact} 
										  style={{ transformOrigin: '0 0 0' }}
										  {...(activeContact ? { timeout: 1000 } : {})}>
										<Typography variant="h2">
											{contact.title}
										</Typography>
									</Grow>
									<Box mt={2} />
									<Box display="flex" justifyContent="flex-start">
										<Typography variant="h6" color="textSecondary" component="p">
											{contact.subtitle} 
											<span>{contact.mail}</span>
											<Box ml={0.3} component="span">
												<CopyToClipboard text={contact.mail}>
													<Tooltip title={contact.copy} aria-label="Copy Paste" placement="right">
														<IconButton>
															<FileCopyOutlinedIcon fontSize="small" color="primary" />
														</IconButton>
													</Tooltip>
												</CopyToClipboard>
											</Box>
										</Typography>
									</Box>
									<Box mt={2} />
									<Grow in={activeContact} 
										  style={{ transformOrigin: '0 0 0' }}
										  {...(activeContact ? { timeout: 1500 } : {})}>
										<Button 
											className={classes.isRounded}
											variant="contained"
											size="large"
											color="secondary" 
											disableElevation
											href={`mailto:${contact.mail}`}>
											{contact.mail}
										</Button>
									</Grow>
								</React.Fragment>
							</VisibilitySensor>
						</Grid>
					</Grid>
				</Container>
			</section>
		</React.Fragment>
	)
}

export default IndexPage;
